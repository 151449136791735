import React from "react";

const companyUrl = "https://www.additiv.com/";

const companyTwitterUrl = "https://twitter.com/additivAG";
const companyLinkedInUrl = "https://www.linkedin.com/company/additiv/";
const companyYoutubeUrl = "https://www.youtube.com/channel/UC1B4S1TtFeBe43RAnSAVhhQ";

const Footer = () => {
  const [copyrightYear] = React.useState(() => new Date().getFullYear());

    return (
        <div className="footer-container">
            <footer id="page-footer" className="l-footer" itemScope="" itemType="https://schema.org/WPFooter">
                <section className="l-section wpb_row us_custom_19737bc8 height_large color_footer-bottom">
                    <div className="l-section-h i-cf">
                        <div className="g-cols vc_row via_flex valign_top type_default stacking_default">
                            <div className="vc_col-sm-2 wpb_column vc_column_container">
                                <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                        <div className="w-image footerlogo align_none">
                                            <a href={companyUrl} aria-label="Link" className="w-image-h">
                                                <img width="99" height="24" src="https://www.additiv.com/wp-content/uploads/2021/10/logo-additiv-white.svg" className="attachment-full size-full" alt="" decoding="async" loading="lazy"></img>
                                            </a>
                                        </div>
                                        <div className="w-separator size_small"></div>
                                        <div className="w-hwrapper align_none valign_top" style={{ "--hwrapper-gap": "10px" }} >
                                            <div className="w-image align_none">
                                                <a target="_blank" href={companyLinkedInUrl} rel="noreferrer" aria-label="Link" className="w-image-h">
                                                    <img width="30" height="30" src="https://www.additiv.com/wp-content/uploads/2021/08/footer-linkedin.svg" className="attachment-full size-full" alt="" decoding="async" loading="lazy"></img>
                                                </a>
                                            </div>
                                            <div className="w-image align_none">
                                                <a target="_blank" href={companyYoutubeUrl} rel="noreferrer" aria-label="Link" className="w-image-h">
                                                    <img width="30" height="30" src="https://www.additiv.com/wp-content/uploads/2021/08/footer-youtube.svg" className="attachment-full size-full" alt="" decoding="async" loading="lazy"></img>
                                                </a>
                                            </div>
                                            <div className="w-image align_none">
                                                <a target="_blank" href={companyTwitterUrl} rel="noreferrer" aria-label="Link" className="w-image-h">
                                                    <img width="30" height="30" src="https://www.additiv.com/wp-content/uploads/2021/08/footer-twitter.svg" className="attachment-full size-full" alt="" decoding="async" loading="lazy"></img>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="vc_col-sm-2 wpb_column vc_column_container">
                                <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                        <div className="w-separator size_custom" style={{ "height": "0.5rem" }} id="r363"></div>
                                        <div className="wpb_text_column us_custom_07255bbd has_text_color">
                                            <div className="wpb_wrapper">
                                                <p>Platform</p>
                                            </div>
                                        </div>
                                        <div className="wpb_text_column us_custom_aef5606f">
                                            <div className="wpb_wrapper">
                                                <p>
                                                    <a href="https://www.additiv.com/orchestration-platform/">Orchestration by additiv</a><br></br>
                                                    <a href="https://www.additiv.com/types-of-orchestration/">Types of orchestration</a><br></br>
                                                    <a href="https://www.additiv.com/orchestration-platform/">Platform</a><br></br>
                                                    <a href="https://www.additiv.com/all-in-one/">All-in-one</a><br></br>
                                                    <a href="https://www.additiv.com/apis/">APIs</a><br></br>
                                                    <a href="https://www.additiv.com/apps/">Apps</a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="vc_col-sm-2 wpb_column vc_column_container">
                                <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                        <div className="w-separator size_custom" style={{ "height": "0.5rem" }} id="jf30"></div>
                                        <div className="wpb_text_column us_custom_07255bbd has_text_color">
                                            <div className="wpb_wrapper">
                                                <p>Solutions</p>
                                            </div>
                                        </div>
                                        <div className="wpb_text_column us_custom_aef5606f">
                                            <div className="wpb_wrapper">
                                                <p>
                                                    <a href="https://www.additiv.com/solutions/">End-to-end value propositions</a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="vc_col-sm-2 wpb_column vc_column_container">
                                <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                        <div className="w-separator size_custom" style={{ "height": "0.5rem" }} id="ga7b"></div>
                                        <div className="wpb_text_column us_custom_07255bbd has_text_color">
                                            <div className="wpb_wrapper">
                                                <p>Verticals</p>
                                            </div>
                                        </div>
                                        <div className="wpb_text_column us_custom_423f8540">
                                            <div className="wpb_wrapper">
                                                <p>
                                                    <a href="https://www.additiv.com/wealth-and-asset-managers-ifas/">Wealth &amp; asset managers, IFAs</a><br></br>
                                                    <a href="https://www.additiv.com/banks-and-neo-banks/">Banks and neo-banks</a><br></br>
                                                    <a href="https://www.additiv.com/pension-and-life-insurance-providers/">Pension and life insurance</a><br></br>
                                                    <a href="https://www.additiv.com/health-insurance-providers/">Health insurance providers</a><br></br>
                                                    <a href="https://www.additiv.com/consumer-platforms/">Consumer platforms</a><br></br>
                                                    <a href="https://www.additiv.com/employee-wellbeing-services/">Employee wellbeing services</a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="vc_col-sm-2 wpb_column vc_column_container">
                                <div className="vc_column-inner"><div className="wpb_wrapper">
                                    <div className="w-separator size_custom" style={{ "height": "0.5rem" }} id="s644"></div>
                                    <div className="wpb_text_column us_custom_07255bbd has_text_color">
                                        <div className="wpb_wrapper">
                                            <p>Ecosystem</p>
                                        </div>
                                    </div>
                                    <div className="wpb_text_column us_custom_aef5606f">
                                        <div className="wpb_wrapper">
                                            <p>
                                                <a href="https://www.additiv.com/ecosystem-marketplace/">Fintech Marketplace</a><br></br>
                                                <a href="https://www.additiv.com/ecosystem-services/">Infrastructures and services partners</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="vc_col-sm-2 wpb_column vc_column_container">
                                <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                        <div className="w-separator size_custom" style={{ "height": "0.5rem" }} id="s1a0"></div>
                                        <div className="wpb_text_column us_custom_07255bbd has_text_color">
                                            <div className="wpb_wrapper">
                                                <p>Company</p>
                                            </div>
                                        </div>
                                        <div className="wpb_text_column us_custom_aef5606f">
                                            <div className="wpb_wrapper">
                                                <p>
                                                    <a href="https://www.additiv.com/insights/">Insights</a><br></br>
                                                    <a href="https://www.additiv.com/company/">Company</a><br></br>
                                                    <a href="https://www.additiv.com/news/">News</a><br></br>
                                                    <a href="https://www.additiv.com/careers/">Careers</a><br></br>
                                                    <a href="https://www.additiv.com/locations/">Locations</a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="l-section wpb_row us_custom_3dfbf90b height_large color_footer-bottom">
                    <div className="l-section-h i-cf">
                        <div className="g-cols vc_row via_flex valign_middle type_default stacking_default">
                            <div className="vc_col-sm-4 wpb_column vc_column_container">
                                <div className="vc_column-inner"><div className="wpb_wrapper">
                                    <div className="wpb_text_column us_custom_883ceba5 nobrm has_text_color">
                                        <div className="wpb_wrapper">
                                            <p>Copyright © {copyrightYear} Additiv AG and/or its affiliates. All rights reserved.</p>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="vc_col-sm-4 wpb_column vc_column_container">
                                <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                        <div className="wpb_text_column us_custom_35d6aa61 footer2links">
                                            <div className="wpb_wrapper">
                                                <p>
                                                    <a href="https://www.additiv.com/privacy-policy/">Privacy Policy</a>&nbsp; |&nbsp;
                                                    {/*<a href="https://www.additiv.com/privacy-policy/#updatecookie">Cookie Settings</a>&nbsp; |&nbsp;*/}
                                                    <a href="https://www.additiv.com/imprint/">Imprint</a>&nbsp; |&nbsp;
                                                    <a href="https://www.additiv.com/compliance-trust-center/">Trust Center</a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="vc_col-sm-4 wpb_column vc_column_container">
                                <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                        <div className="wpb_text_column us_custom_eb849559 footerimglinks">
                                            <div className="wpb_wrapper">
                                                <p>
                                                    <a href="https://www.iaasb.org/publications/international-standard-assurance-engagements-isae-3000-revised-assurance-engagements-other-audits-or-0" target="_blank" rel="noreferrer">
                                                        <img decoding="async" loading="lazy" className="alignnone" src="https://www.additiv.com/wp-content/uploads/2021/08/additiv-SOC-Type2.png" alt="additiv" width="200" height="200"></img>
                                                    </a>
                                                    <a href="https://ecovadis.com/" target="_blank" rel="noreferrer">
                                                        <img decoding="async" src="https://www.additiv.com/wp-content/uploads/2021/08/additiv-EcoVadis-certified.png" alt="additiv"></img>
                                                    </a>
                                                    <a href="http://www.esgfintech100.com/" target="_blank" rel="noreferrer">
                                                        <img decoding="async" src="https://www.additiv.com/wp-content/uploads/2021/02/additiv-ESGFinTech100.png" alt="additiv"></img>
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </footer>
        </div>
  )
}

export default Footer

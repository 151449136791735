import React from "react"
import styled from "styled-components"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Footer from "../components/Footer"
import heroSrc from "../images/hero.jpg"

const Container = styled.div`
  padding: 3rem 1rem;
  line-height: 1.5;
  max-width: 1170px;
  margin: 0 auto;
  display: grid;

  @media print, screen and (min-width: 50rem) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 48px;
  }

  li {
    margin-top: 1rem;
  }

  code {
    font-size: 0.929em;
    color: #3e4b8e;
    word-break: break-all;
    font-family: "Roboto Mono", monospace;
  }

  hr {
    height: 1px;
    border: none;
    border-bottom: 1px solid #ececec;
  }
`

const HeroImg = styled.img`
  max-width: 100%;
`

export default () => (
  <Layout>
    <SEO />
    <main className="main">
      <Container>
        <section>
          <h1>Welcome to the DFS API</h1>
          <p>
            The DFS API catalog provides interested parties, such as implementation
            partners, an insight into our API offering so that solutions can be designed
            based on these focussing on the functionalities required to build 
            Hybrid Wealth Management solutions. 
            The target audience is primarily software engineers and business analysts
            with know-how in the finance industry and specifically the wealth domain.            
          </p>
          <p>
            This portal will continuously grow to be the main resource for docs,
            guides, cookbooks and examples for additiv’s DFS API products and
            services.
          </p>
          <p>
            The DFS APIs are the backbone of the solution and are used by
            the standard B2B and B2C web applications created by additiv. 
            Specifically, <a href="/wealth">the Wealth APIs</a> are the backbone
            of additiv’s Hybrid Wealth Management offering.
          </p>
        </section>
        <HeroImg src={heroSrc} />
        <section>
          <h2>Introduction</h2>
          <p>
            DFS services expose a RESTful API that aims to speak the business
            domain language while <em>hiding</em> the business logic complexity 
            from the consuming systems.
          </p>
          <h3>Multiple API hosts</h3>
          <p>
            DFS exposes multiple API groups, each serving different purposes. 
            Different API groups may reside under different hosts while a single
            group may serve 1-n hosts.
          </p>
          <h3>API Gateway</h3>
          <p>
            In a microservices architecture, client apps usually need to
            consume functionality from more than one microservice / host. If
            that consumption is performed directly, the client needs to handle
            multiple calls to microservice endpoints.{" "}
            <a href="https://docs.microsoft.com/en-us/dotnet/architecture/microservices/architect-microservice-container-applications/direct-client-to-microservice-communication-versus-the-api-gateway-pattern">
              (source: The API gateway pattern versus the Direct
              client-to-microservice communication)
            </a>
          </p>
          <p>
            DFS APIs continuously evolve and new microservices / hosts are
            introduced or existing microservices are updated. This, among many
            other reasons is why live APIs are accessed by client apps via an
            API gateway.
          </p>
          <p>
            For simplicity, this portal shows both read-only and mutating endpoints in the standalone
            form and not under the API gateway. 
            Therefore, the grouping of the endpoints (e.g. <a href="/wealth">Wealth</a>, <a href="/dms">Dms</a>) is insignificant and done for practical reasons.
          </p>
          <h2>Getting Started</h2>
          <p>
            All consumers using the DFS APIs (e.g. client applications, postman
            users etc.), must be uniquely identified and authorized to access
            DFS endpoints regardless if there is an authenticated person.
          </p>
          <p>          
            Each consumer might be authorized to access a specific sub-set of 
            endpoints based on the app needs and usage.            
          </p>
          <p>
            This is achieved via a set of credentials provided to every consumer 
            of the APIs (referred to as Client App) which identifies the App and allows
            the system to determine the scope(s) and access policy group(s) assigned to it.            
          </p>
          <ul>
            <li>
              <code>Client ID</code> (i.e. username of the app)
            </li>
            <li>
              <code>Client Secret</code> (i.e. password of the app to match
              the <code>Client ID</code>)
            </li>
          </ul>
          <p>
            Since DFS APIs are designed for multi tenancy, these credentials
            also allow the system to identify the tenant(s) which the App is
            authorized to access.
          </p>
          <p>
            All endpoints that require such authorization (the vast majority) 
            are protected with a Bearer Authorization token (JWT) and require 
            the caller to pass the string <code>Bearer {"{"}JwtAccessToken{"}"}</code> in the Authorization header.
          </p>
          <h3>Obtaining Access Token</h3>
          <p>
            A client application can easily obtain the needed token by issuing a request to the 
            <code> connect/token</code> endpoint of DFS identity server with valid app credentials, 
            the required scope, grant_type etc. Depending on the requirements and permissions, 
            different token types can be requested (e.g User token, App token).
            For example: <br></br>           
            <code> 
              {"{"}<br></br>
                scope: "DfsApi",<br></br>
                grant_type: "client_credentials",<br></br>
                client_id: "v072tGsxjdsaTuZlzil7TnDUAqr5d3",<br></br>
                client_secret: "XMevpAsNKckL7Z3veXkayxgaleQLEp",<br></br>
                tenant_uid: "X101XX1X-0X1X-1X0X-X0XX-X10X10X101XX"<br></br>
              {"}"}
            </code>
          </p>                    
          <h3>Playing with the APIs</h3>
          <p>
            The API portal does not offer, at this point, ability to get access
            keys and execute calls against the endpoints. To get access to a
            running system with live APIs, please {" "}
            <a href="https://www.additiv.com/contact/">contact us</a>{" "}
            with your specific needs.
          </p>
          <h3>Not a developer?</h3>
          <p>
            <a href="https://www.additiv.com/contact/">Contact us</a> to
            schedule a demo of our standard Hybrid Wealth apps to learn what can
            be achieved with these APIs and how additiv can solve your business
            needs.
          </p>
          <p>
            The endpoints provided in this portal are not a guarantee of
            service. Partners authorized to access a live system will get
            regular updates which includes new and updated APIs. This portal is
            updated regularly but less frequently.
          </p>
          <h3>License &amp; Terms of Use</h3>
          <p>
            <em>
              The additiv DFS API catalog can only be used by additiv’s internal
              engineering teams or by licensed parties under a license agreement
              containing restrictions on use and disclosure. The DFS software
              and documentation, including the APIs, are protected by
              intellectual property laws. additiv’s objective is to focus on
              developing robust APIs as it designs and further develops the
              available services. The APIs may change in the course of
              architectural improvements, addition of capabilities, and
              optimization of performance. The API endpoints provided in this
              portal are not a guarantee of service. Parties authorized to
              access a live system will get regular updates which includes new,
              deprecated and updated APIs and in some cases, APIs might also be
              removed. Except as expressly permitted in individual license
              agreements or allowed by law, you may not use, copy, reproduce,
              translate, broadcast, modify, license, transmit, distribute,
              exhibit, perform, publish or display any part of the content on
              this site / document, in any form, or by any means.
            </em>
          </p>
          <hr />
          <p>
            Last update: <strong>DFS version 2024.2</strong>
          </p>
          <p>
            Passionate about creating beautiful software that change the future
            of Banking?{" "}
            <a href="https://additiv.com/careers/">
              Join additiv’s engineering teams.
            </a>
          </p>
        </section>
      </Container>
    </main>
    <Footer />
  </Layout>
)
